import React from "react";
import { useNavigate } from "react-router-dom";

export default function CourseDetails() {
  let navi = useNavigate();

  function GotoPurchase() {
    navi("/Purchase");
  }
  return (
    <div className="flex flex-col items-center p-8">
      {/* Video Section */}
      <div className="mb-8">
        <iframe
          width="560"
          height="315"
          src="https://youtu.be/7o5npDWv9rU?si=hR1H-BkYFi4pvKcN" // replace with the actual video URL
          title="Course Video"
          frameBorder="2"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>

      {/* Course Details Section */}
      <div className="max-w-xl text-center">
        <h2 className="text-2xl font-bold mb-4">Course Title</h2>
        <p className="mb-6">
          This is a brief description of the course. It provides an overview of
          what will be covered and highlights the key learning objectives.
        </p>

        {/* Author Description */}
        <div className="mt-8 p-4 bg-gray-100 rounded-md">
          <h3 className="text-xl font-semibold">Author: John Doe</h3>
          <p className="mt-2">
            John is an experienced instructor with a background in teaching
            programming and web development.
          </p>
        </div>

        {/* Price and Purchase Section */}
        <div className="mt-10">
          <h3 className="text-lg font-semibold">Price: INR 799</h3>
          <button
            className="mt-4 bg-blue-500 text-white py-2 px-6 text-lg rounded hover:bg-blue-600"
            onClick={GotoPurchase}
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
}
