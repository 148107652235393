import { Button } from "bootstrap";
import React from "react";

const CourseModulePage = () => {
  return (
    <div className="flex flex-col md:flex-row min-h-screen p-4 bg-gray-100">
      {/* Left Column: Video, Notes, and Resources */}
      <div className="md:w-2/3 w-full p-4">
        {/* Video Section */}
        <div className="bg-white p-4 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-bold mb-4">Course Video</h2>
          <div className="aspect-w-16 aspect-h-9 h-96">
            <iframe
              src="https://www.youtube.com/embed/dQw4w9WgXcQ"
              title="Course Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-full rounded-md"
            ></iframe>
          </div>
        </div>

        {/* Course Notes Section */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
  <h3 className="text-lg font-semibold mb-4">Course Notes</h3>

  {/* Textarea for Course Notes */}
  <textarea
    className="w-full p-3 mb-4 bg-slate-100 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
    placeholder="Write your notes here..."
  ></textarea>

  {/* Take Note Button */}
  <button className="w-full bg-green-500 text-white p-3 rounded-md hover:bg-green-600 transition">
    Take Note
  </button>

  {/* Display Course Notes */}
  <p className="text-gray-700 mt-4">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.
    Praesent libero. Sed cursus ante dapibus diam.
  </p>
</div>

        {/* Course Resources Section */}
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-2">Course Resources</h3>
          <ul className="list-disc list-inside text-gray-700">
            <li>
              <a
                href="#"
                className="text-blue-500 hover:underline"
              >
                Resource 1 (PDF)
              </a>
            </li>
            <li>
              <a
                href="#"
                className="text-blue-500 hover:underline"
              >
                Resource 2 (External Link)
              </a>
            </li>
            <li>
              <a
                href="#"
                className="text-blue-500 hover:underline"
              >
                Resource 3 (Downloadable)
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Right Column: Course Contents */}
      <div className="md:w-1/3 w-full p-4">
        <div className="bg-white p-4 rounded-lg shadow-md sticky top-4">
          <h3 className="text-lg font-bold mb-4">Course Contents</h3>
          <ul className="list-decimal list-inside text-gray-700">
            <li>Introduction</li>
            <li>Chapter 1: Getting Started</li>
            <li>Chapter 2: Core Concepts</li>
            <li>Chapter 3: Advanced Techniques</li>
            <li>Chapter 4: Best Practices</li>
            <li>Conclusion</li>
            <li>Final Assessment</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CourseModulePage;
