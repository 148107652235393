import React, { useEffect } from "react";
import Myimage from "../assets/1705292027492.jpeg";
import bookus from "../assets/3_20240902_124421_0001.jpg";
import impimg from "../assets/image.png";

const ResourcesPage = () => {
  const youtubeLinks = [
    {
      title: "VC Sir: How Instagram is Hijacking your Dreams!",
      url: "https://www.youtube.com/embed/7o5npDWv9rU",
    },
    {
      title: "Public Speaking",
      url: "https://www.youtube.com/embed/B28cOIiJB50",
    },
    {
      title: "Population : Boon or Bane",
      url: "https://youtube.com/embed/JS0ugdvAFT8?si=cpea_Tn49wTDevgE",
    },
  ];

  const quotes = [
    "The best way to predict the future is to create it. - Peter Drucker",
    "Success is not the key to happiness. Happiness is the key to success. - Albert Schweitzer",
    "Don’t watch the clock; do what it does. Keep going. - Sam Levenson",
  ];
  useEffect(() => {
    const page = document.getElementById("page");
    setTimeout(() => {
      page.classList.remove("opacity-0");
    }, 100); // Adjust the delay as needed
  }, []);

  return (
    <div
      id="page"
      className="mx-auto p-8 bg-white text-white min-h-screen opacity-0 transition-opacity duration-700 delay-300"
    >
      <h1 className="text-center text-5xl font-bold mb-12 text-blue-500">
        Resources
      </h1>

      {/* YouTube Video Links */}
      <div className="youtube-links mb-16">
        <h2 className="text-3xl font-semibold mb-8 text-sky-500">
          YouTube Videos
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {youtubeLinks.map((link, index) => (
            <div
              key={index}
              className="rounded-lg shadow-lg overflow-hidden bg-gray-800"
            >
              <iframe
                className="w-full h-64"
                src={link.url}
                title={link.title}
                frameBorder="0"
                allowFullScreen
              ></iframe>
              <div className="p-4">
                <h3 className="text-lg font-medium mb-2">{link.title}</h3>
                <span className="text-blue-500 text-sm font-medium">
                  *latest*
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Quotes Collection */}
      <div className="quotes mb-16">
        <h2 className="text-3xl font-semibold mb-8 text-blue-500">
          News Board
        </h2>
        <div className="space-y-6">
          {quotes.map((quote, index) => (
            <div
              className="bg-gray-800 p-6 rounded-lg shadow-md border-l-4 border-blue-500"
              key={index}
            >
              <p className="text-lg font-light">{quote}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResourcesPage;
