import React from "react";
import { useNavigate } from "react-router-dom";

export default function CourseItem() {
  let navigation = useNavigate();

  const Coursedeets = (e) => {
    navigation("/CourseDetail");
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="https://picsum.photos/200/300"
          alt="Course"
          className="w-full h-48 object-cover"
        />
        <div className="p-4">
          <h2 className="text-xl font-bold mb-2">Master Public Speaking</h2>
          <p className="text-gray-700 mb-1">
            Author/Lecturer: Dr. Vijender Chauhan
          </p>
          <p className="text-gray-600 mb-4">Time: 12 hours 30 minutes</p>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Enroll for free
          </button>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="https://picsum.photos/200/301"
          alt="Course"
          className="w-full h-48 object-cover"
        />
        <div className="p-4">
          <h2 className="text-xl font-bold mb-2">IELTS for Beginners</h2>
          <p className="text-gray-700 mb-1">
            Author/Lecturer: Shreyasi, MTESOL Instructor
          </p>
          <p className="text-gray-600 mb-4">Time: 7 hours 45 minutes</p>
          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            Rs 499
          </button>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="https://picsum.photos/200/302"
          alt="Course"
          className="w-full h-48 object-cover"
        />
        <div className="p-4">
          <h2 className="text-xl font-bold mb-2">Ace Corporate Interviews</h2>
          <p className="text-gray-700 mb-1">
            Author/Lecturer: Dr. Vijender Chauhan
          </p>
          <p className="text-gray-600 mb-1">Time: 3 hours 15 minutes</p>
          <p className="text-gray-600 mb-4">1 to 1 online interview</p>
          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Rs 699
          </button>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="https://picsum.photos/200/302"
          alt="Course"
          className="w-full h-48 object-cover"
        />
        <div className="p-4">
          <h2 className="text-xl font-bold mb-2">
            UPSC Interview Prep: Batch 1
          </h2>
          <p className="text-gray-700 mb-1">
            Author/Lecturer: Dr. Vijender Chauhan
          </p>
          <p className="text-gray-600 mb-4">Time: 6 hours 15 minutes</p>
          <button
            onClick={Coursedeets}
            className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}
