import React from "react";

const ForumPage = () => {
  return (
    <div className="h-screen w-full flex flex-col bg-gray-100">
      {/* Top Bar */}
      <div className="flex items-center justify-between bg-blue-600 text-white px-6 py-4">
        <h1 className="text-2xl font-bold">Forum Title</h1>
        <p className="text-sm italic">Admin: John Doe</p>
      </div>

      {/* Main Content */}
      <div className="flex flex-1">
        {/* Left: Post Feed */}
        <div className="w-1/2 border-r border-gray-300 overflow-y-auto p-4">
          <h2 className="text-xl font-semibold mb-4">Post Feed</h2>
          <div className="space-y-4">
            {/* Example Posts */}
            <div className="bg-white shadow p-4 rounded">
              <h3 className="font-bold text-lg">Post Title 1</h3>
              <p className="text-gray-700 mt-2">
                This is the content of the first post...
              </p>
            </div>
            <div className="bg-white shadow p-4 rounded">
              <h3 className="font-bold text-lg">Post Title 2</h3>
              <p className="text-gray-700 mt-2">
                This is the content of the second post...
              </p>
            </div>
            {/* Add more posts here */}
          </div>
        </div>

        {/* Right: Chat Window */}
        <div className="w-1/2 p-4 flex flex-col">
          <h2 className="text-xl font-semibold mb-4">Chat Window</h2>
          <div className="flex-1 bg-white shadow rounded p-4 overflow-y-auto">
            {/* Example Chat Messages */}
            <div className="space-y-3">
              <div className="text-sm">
                <span className="font-bold">Alice:</span> Hi everyone!
              </div>
              <div className="text-sm">
                <span className="font-bold">Bob:</span> Welcome to the forum!
              </div>
              {/* Add more messages here */}
            </div>
          </div>
          <div className="mt-4">
            <input
              type="text"
              placeholder="Type a message..."
              className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring focus:ring-blue-400"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForumPage;
