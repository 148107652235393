import { configureStore } from '@reduxjs/toolkit';

// Import your reducers (we will create these later)
import CourseReducer from './CourseSlice'; // Example of a reducer

// Configure the store
const store = configureStore({
  reducer: {
    course: CourseReducer, // Example slice
  },
});

export default store;
