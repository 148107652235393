import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Forum = () => {
  const [threads, setThreads] = useState([]);
  const [newThreadTitle, setNewThreadTitle] = useState("");
  const [newThreadDescription, setNewThreadDescription] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    // Replace with your API endpoint to fetch forum threads
    axios
      .get("/api/forum/threads")
      .then((response) => {
        setThreads(response.data);
      })
      .catch((error) => {
        console.error("Error fetching forum threads:", error);
      });
  }, []);

  const handleStartDiscussion = () => {
    // Replace with your API endpoint to create a new forum thread
    axios
      .post("/api/forum/threads", {
        title: newThreadTitle,
        description: newThreadDescription,
      })
      .then((response) => {
        setThreads([...threads, response.data]);
        setNewThreadTitle("");
        setNewThreadDescription("");
      })
      .catch((error) => {
        console.error("Error starting discussion:", error);
      });
  };

  const handleSendMessage = () => {
    // Replace with your API endpoint to send a new chat message
    axios
      .post("/api/forum/messages", {
        message: newMessage,
      })
      .then((response) => {
        setChatMessages([...chatMessages, response.data]);
        setNewMessage("");
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  return (
    <div className="p-6 bg-gray-200 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-center text-blue-600">
        Forum
      </h1>

      {/* Forum Threads */}
      <div className="space-y-6">
        {threads.length > 0 ? (
          threads.map((thread) => (
            <div
              key={thread.id}
              className="bg-blue-400  bg-opacity-30shadow-md rounded-lg p-6 space-y-4"
            >
              <h2 className="text-2xl font-bold text-blue-600 hover:underline">
                <Link to={`/forum/${thread.id}`}>{thread.title}</Link>
              </h2>
              <p className="text-sm text-gray-500">
                Posted by {thread.author} on{" "}
                {new Date(thread.createdAt).toLocaleDateString()}
              </p>
              <p className="text-gray-700">{thread.description}</p>

              {/* Chatbox */}
              <div className="mt-4">
                <h3 className="text-lg font-bold">Chatbox</h3>
                <ul className="space-y-2 bg-gray-50 p-4 rounded-lg shadow-inner">
                  {chatMessages.map((message) => (
                    <li key={message.id} className="text-gray-600">
                      {message.message}
                    </li>
                  ))}
                </ul>
                <div className="mt-4 flex">
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                    className="flex-1 p-2 border border-gray-300 rounded-md"
                  />
                  <button
                    onClick={handleSendMessage}
                    className="ml-2 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="h-48 bg-blue-400 bg-opacity-30 text-blue-800 flex items-center justify-center rounded-lg">
            <p>No threads available. Be the first to start a discussion!</p>
          </div>
        )}
      </div>

      {/* Start a New Discussion */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-4 text-blue-600">
          Start a Discussion
        </h2>
        <div className=" bg-blue-600 bg-opacity-30 p-6 rounded-lg shadow-md space-y-4">
          <input
            type="text"
            value={newThreadTitle}
            onChange={(e) => setNewThreadTitle(e.target.value)}
            placeholder="Thread Title"
            className="w-full p-3 border border-gray-300 rounded-md"
          />
          <textarea
            value={newThreadDescription}
            onChange={(e) => setNewThreadDescription(e.target.value)}
            placeholder="Thread Description"
            className="w-full p-3 border border-gray-300 rounded-md"
          />
          <button
            onClick={handleStartDiscussion}
            className="w-full bg-green-500 text-white p-3 rounded-md hover:bg-green-600"
          >
            Start Discussion
          </button>
        </div>
      </div>
    </div>
  );
};

export default Forum;
