import React, { useState } from "react";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// Import images
import bg from "../assets/VCdreamy.jpeg";
import port from "../assets/DSC08971.jpg";
import boss from "../assets/bossvc.jpeg";
import leo from "../assets/ARD_9786.jpg";

const MySwal = withReactContent(Swal);

const BookingPage = () => {
  const [isPageVisible, setIsPageVisible] = useState(false);

  // Fade in effect
  React.useEffect(() => {
    const timer = setTimeout(() => setIsPageVisible(true), 100);
    return () => clearTimeout(timer);
  }, []);

  // Handle Get In Touch functionality
  // Handle Get In Touch functionality
  const handleGetInTouch = () => {
    MySwal.fire({
      title: <p className="text-2xl font-bold text-white">Contact Us</p>,
      html: (
        <div className="flex flex-col space-y-4 text-left">
          <p className="text-white">
            Please select one of the options below to proceed.
          </p>
        </div>
      ),
      showConfirmButton: false,
      showCloseButton: true,
      didOpen: () => {
        const popup = Swal.getPopup();

        // Button for Business Enquiries
        const businessInquiryButton = document.createElement("button");
        businessInquiryButton.innerText = "Business Enquiries";
        businessInquiryButton.className =
          "w-full mt-2 py-2 px-4 bg-blue-500 text-white font-bold rounded-full hover:bg-blue-600 transition-colors";
        businessInquiryButton.onclick = () =>
          (window.location.href = "mailto:drvijender.singh@confluencr.com");

        // Button for Book PSD for Your Event
        const bookEventButton = document.createElement("button");
        bookEventButton.innerText = "Book PSD for Your Event";
        bookEventButton.className =
          "w-full mt-2 py-2 px-4 bg-red-500 text-white font-bold rounded-full hover:bg-red-600 transition-colors";
        bookEventButton.onclick = () =>
          (window.location.href = "mailto:events@psdtalk.com");

        popup.appendChild(businessInquiryButton);
        popup.appendChild(bookEventButton);
      },
      customClass: {
        popup:
          "bg-blue-400 bg-opacity-30 backdrop-blur-lg rounded-lg shadow-lg p-6",
      },
    });
  };

  // Reusable Card Component
  const ImageTextCard = ({
    image,
    title,
    description,
    imagePosition = "top",
  }) => (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row mb-6 max-w-2xl mx-auto">
      {imagePosition === "top" && (
        <div className="w-full md:w-1/2 h-64 md:h-auto">
          <img src={image} alt={title} className="w-full h-full object-cover" />
        </div>
      )}
      <div className="p-6 flex flex-col justify-center w-full md:w-1/2">
        <h2 className="text-xl font-semibold mb-2 text-center">{title}</h2>
        <p className="text-gray-600 text-center">{description}</p>
      </div>
      {imagePosition === "bottom" && (
        <div className="w-full md:w-1/2 h-64 md:h-auto">
          <img src={image} alt={title} className="w-full h-full object-cover" />
        </div>
      )}
    </div>
  );

  return (
    <div
      className={`relative min-h-screen bg-slate-700 transition-opacity duration-700 ${
        isPageVisible ? "opacity-100" : "opacity-0"
      } flex flex-col`}
    >
      {/* Background Image */}
      <div className="absolute inset-0">
        <img src={bg} className="w-full h-full object-cover" alt="Background" />
      </div>

      {/* Content Container */}
      <div className="relative z-10 container mx-auto px-4 py-8">
        {/* Header Section */}
        <div
          onClick={handleGetInTouch}
          className="text-center bg-blue-400 bg-opacity-20 p-6 rounded-lg shadow-lg mb-8 backdrop-blur-sm border border-white/30 cursor-pointer hover:bg-opacity-30 transition-all"
        >
          <h2 className="text-2xl md:text-3xl font-extrabold text-white mb-4">
            Book Now
          </h2>
          <p className="text-white text-lg md:text-2xl font-semibold">
            Bring Dr. Vijender Chauhan to Your Campus: Empowering Students and
            UPSC Aspirants for Success. Unlock the secrets to success with Dr.
            Chauhan, a renowned expert in public speaking, communication skills,
            and personality development.
          </p>
        </div>

        {/* Content Cards Section */}
        <div className="space-y-6">
          <ImageTextCard
            image={port}
            title="Engaging, Actionable Content"
            description="Dr. Chauhan's seminars are transformative experiences, equipping students with practical tools and strategies for UPSC and personal development."
          />
          <ImageTextCard
            image={boss}
            imagePosition="bottom"
            title="Tailored for UPSC"
            description="Dr. Chauhan provides guidance on interviews, group discussions, and confidence."
          />
          <ImageTextCard
            image={leo}
            title="Holistic Development"
            description="Beyond UPSC, Dr. Chauhan helps students build life skills."
          />
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
