import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Use Routes instead of Switch
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
// Import other pages like Courses, Forum, Login, Register
import Courses from "./pages/Courses/Courses";
import Forum from "./pages/Forum/Forum";
import Login from "./pages/Login";
import Us from "./pages/About";
import ResourcesPage from "./pages/Resources";
import CourseDetails from "./pages/Courses/Coursedetails";
import ProfilePage from "./pages/Dashboards/Profile";
import Purchase from "./pages/Courses/Purchase";
import CourseModulePage from "./pages/Courses/CourseModule";
import BookingPage from "./pages/Booking";
import ForumPage from "./pages/Forum/forumscreen";

const App = () => {
  return (
    <div style={{ minWidth: "100vw", backgroundColor: "#737373" }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/forum/:id" element={<ForumPage />} />

        <Route path="/login" element={<Login />} />
        <Route path="/About" element={<Us />} />
        <Route path="/Resources" element={<ResourcesPage />} />
        <Route path="/CourseDetail" element={<CourseDetails />} />
        <Route path="/Profile" element={<ProfilePage />} />
        <Route path="/Purchase" element={<Purchase />} />
        <Route path="/course/:id" element={<CourseModulePage />} />
        <Route path="/Booking" element={<BookingPage />} />

        {/* Add other routes as needed */}
      </Routes>
    </div>
  );
};

export default App;
