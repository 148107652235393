// src/pages/Courses.js

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Courseitem from "./CourseItem";

const Courses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Replace with your API endpoint to fetch courses
    axios
      .get("/api/courses")
      .then((response) => {
        setCourses(response.data);
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  }, []);

  return (
    <div className="bg-slate-200">
      <h1 style={{ backgroundColor: "#b34d4d", padding: 30 ,opacity:1,color:'white'}}>Courses</h1>
      <div>
        <Courseitem></Courseitem>
        {/* {courses.length > 0 ? (
                    courses.map(course => (
                        <div key={course.id} className="course-item">
                            <h2>
                                <Link to={`/courses/${course.id}`}>{course.title}</Link>
                            </h2>
                            <p>Instructor: {course.instructor}</p>
                            <p>{course.description}</p>
                        </div>
                    ))
                ) : (
                    <p>No courses available at the moment.</p>
                )} */}
      </div>
    </div>
  );
};

export default Courses;
