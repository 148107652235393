import React, { useEffect } from "react";
import v1 from "../assets/image.png";
import v2 from "../assets/Screenshot from 2024-09-01 17-23-37.png";
import main from "../assets/Welcometo.png";
import booker from "../assets/3_20240902_124421_0001.jpg";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const gotoCourse = (id) => {
    console.log(`Navigating to course with ID: ${id}`);
  };
  const gotoBook = () => {
    navigate("/Booking");
    console.log("Navigating to Booking page");
  };

  useEffect(() => {
    const page = document.getElementById("head");
    setTimeout(() => {
      page.classList.remove("opacity-0");
    }, 100); // Adjust the delay as needed
  }, []);

  return (
    <div
      className="bg-blue-100 min-h-screen w-screen opacity-0 transition-opacity duration-700 delay-300"
      id="head"
    >
      <header
        className="bg-contain bg-center bg-no-repeat h-[50vh] md:h-screen w-full flex items-stretch justify-end px-5 mx-1 md:px-20 md:pt-20 mb-4 md:mb-8 animate-fadeInBg mt-1"
        style={{ backgroundImage: `url(${main})` }}
      ></header>
      {/* <header
        className="bg-cover bg-center bg-no-repeat h-[50vh] md:h-screen w-full flex items-start justify-end px-5 mx-1 md:px-20 pt-7 md:pt-20 mb-4 md:mb-8 animate-fadeInBg mt-1"
        style={{ backgroundImage: `url(${main})` }}
      > */}
      {/* <div className="text-right space-y-4 max-w-full md:max-w-lg animate-fadeInText delay-1000">
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold leading-tight md:leading-snug">
            Welcome to
          </h1>
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold leading-tight md:leading-snug">
            Please Sit Down
          </h1>
          <h3 className="text-white text-base sm:text-lg md:text-xl lg:text-2xl leading-relaxed md:leading-normal">
            An educational endeavour brought to you by the house of PSDMedia
          </h3>
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold leading-tight md:leading-snug">
            And
          </h1>
        </div> */}
      {/* </header> */}

      <div
        className="bg-contain bg-center bg-no-repeat h-[50vh] md:h-screen w-full cursor-pointer animate-fadeInBg delay-3000"
        onClick={gotoBook}
        style={{ backgroundImage: `url(${booker})` }}
      ></div>

      <section className="w-11/12 mx-auto mt-16 text-center">
        <h1 className="text-2xl md:text-3xl font-bold mb-8">
          Visit PSDMedia on Social Media
        </h1>
        <div className="flex flex-wrap justify-center gap-4 animate-fadeInSlider delay-2000">
          {[
            {
              url: "https://www.instagram.com/masijeevi/",
              img: "https://2235233.fs1.hubspotusercontent-na1.net/hubfs/2235233/blog-import/2022/07-22-Jul/every-social-media-logo-and-icon-in-one-handy-place-instagram.png",
              alt: "Instagram",
            },
            {
              url: "https://twitter.com/masijeevi",
              img: "https://www.sendible.com/hubfs/blog-import/2024/02-24-Feb/social-media-icons-x-logo-black.png",
              alt: "Twitter",
            },
            {
              url: "https://www.linkedin.com/in/vijendermasijeevi/",
              img: "https://f.hubspotusercontent30.net/hubfs/2235233/blog-import/2020/20-08-Aug/sm-icons-linkedin-in-logo.png",
              alt: "LinkedIn",
            },
            {
              url: "https://www.youtube.com/@PleaseSitDown",
              img: "http://assets.sendible.com.s3.amazonaws.com/blog/images/17-dec/sm-icons-youtube.png",
              alt: "YouTube",
            },
          ].map((media, index) => (
            <div
              key={index}
              className="w-40 h-40 sm:w-48 sm:h-48 md:w-72 md:h-20 pb-7 bg-cover rounded-lg transition-transform duration-300 transform hover:scale-110"
              onClick={() => (window.location.href = media.url)}
            >
              <img
                src={media.img}
                alt={media.alt}
                className="w-full h-full object-contain"
              />
            </div>
          ))}
        </div>
      </section>

      <footer className="bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 py-12 md:py-16 px-6 md:px-12 text-center flex flex-col items-center animate-fadeInBg delay-4000">
        <div className="space-y-6 max-w-4xl">
          <h1 className="text-white text-4xl md:text-5xl font-extrabold tracking-wide">
            Please Sit Down
          </h1>
          <p className="text-white text-lg md:text-xl font-medium">
            An educational endeavour brought to you by the house of
            <span className="text-yellow-300 font-semibold"> PSDMedia</span>
          </p>
          <h2 className="text-white text-2xl md:text-3xl font-bold">
            PSD Learning 2024
          </h2>
          <p className="text-gray-200 text-sm md:text-base">
            Empowering Education, Innovating Futures.
          </p>
          <p className="text-gray-300 text-sm md:text-base">
            Made with ❤️ in India
          </p>
        </div>

        <div className="mt-8 flex space-x-6">
          {[
            {
              url: "https://www.instagram.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/2111/2111463.png",
              alt: "Instagram",
            },
            {
              url: "https://www.twitter.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/733/733579.png",
              alt: "Twitter",
            },
            {
              url: "https://www.linkedin.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/174/174857.png",
              alt: "LinkedIn",
            },
            {
              url: "https://www.youtube.com/",
              icon: "https://cdn-icons-png.flaticon.com/512/1384/1384060.png",
              alt: "YouTube",
            },
          ].map((media, index) => (
            <a
              key={index}
              href={media.url}
              target="_blank"
              rel="noopener noreferrer"
              className="w-12 h-12 md:w-16 md:h-16 bg-white rounded-full flex items-center justify-center shadow-lg transform transition duration-300 hover:scale-110 hover:bg-yellow-300"
            >
              <img
                src={media.icon}
                alt={media.alt}
                className="w-8 h-8 md:w-10 md:h-10 object-contain"
              />
            </a>
          ))}
        </div>
      </footer>
    </div>
  );
};

export default Home;
