import React from "react";
import { Link } from "react-router-dom"; // Assuming you are using React Router for navigation

const ProfilePage = ({ onLogout,loginstate }) => {
  const purchasedCourses = [
    { 
      id: 1,
      title: "React for Beginners",
      instructor: "Jane Smith",
      hoursRemaining: 12,
    },
    {
      id: 2,
      title: "Advanced JavaScript",
      instructor: "John Doe",
      hoursRemaining: 8,
    },
    {
      id: 3,
      title: "Web Development Bootcamp",
      instructor: "Sarah Johnson",
      hoursRemaining: 20,
    },
    {
      id: 4,
      title: "UI/UX Design Principles",
      instructor: "Emily Davis",
      hoursRemaining: 5,
    },
  ];
  const user = {
    name: "John Doe",
    email: "john.doe@example.com",
    memberSince: "January 2022",
  };
  return (
    <div className="min-h-screen bg-slate-400 text-white p-8">
      <h1 className="text-3xl font-bold mb-6">Welcome, {user?.name}</h1>

      {/* Profile Dashboard Section */}
      <div className="flex justify-center mb-8">
        <div className="bg-gray-800 p-6 rounded-lg w-full max-w-md text-center">
          <h2 className="text-xl font-semibold mb-4">Profile Dashboard</h2>
          <div className="text-left">
            <p className="mb-2">Name: {user?.name}</p>
            <p className="mb-2">Email: {user?.email}</p>
            <p className="mb-2">Member Since: {user?.memberSince}</p>
          </div>
          <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
            View Invoices
          </button>
        </div>
      </div>

      {/* Purchased Courses Section */}
      <div className="purchased-courses bg-gray-900 p-6 rounded-lg w-full max-w-4xl mx-auto">
        <h2 className="text-2xl font-semibold mb-4">Purchased Courses</h2>

        <ul className="space-y-4">
          {purchasedCourses?.map((course) => (
            <li
              key={course?.id}
              className="flex justify-between items-center bg-gray-800 p-4 rounded-lg"
            >
              <div>
                <Link
                  to={`/coursemodule`}
                  className="text-blue-400 font-bold text-lg"
                >
                  {course?.title}
                </Link>
                <p className="text-sm text-gray-400">
                  Instructor: {course?.instructor}
                </p>
              </div>
              <p className="text-sm text-gray-300">
                {course?.hoursRemaining} hrs remaining
              </p>
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-8 flex justify-center">
        <button
          className="bg-red-600 text-white py-2 px-4 rounded hover:bg-red-700"
          onClick={onLogout}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default ProfilePage;
